import dynamic from 'next/dynamic'
import React, { ReactNode, useRef, useState } from 'react'

import SaveProjectIllustration from '../assets/icons/save-project-illustration.svg'
import { useInternationalization } from './InternationalizationContext'
import { useProjectState } from './ProjectStateContext'

const SaveProjectConfirmModal = dynamic(
  () => import('../components/editor/SaveProjectConfirmModal/SaveProjectConfirmModal'),
)

type FetchType = {
  handleGoToDiffProject: (cb: () => void, saveProject: () => void) => void
  goToDiffProjectCallback: any
}

export const GoToDiffProjectStateContext = React.createContext<FetchType>({
  handleGoToDiffProject: () => {},
  goToDiffProjectCallback: () => {},
})

export const GoToDiffProjectStateProvider = ({ children }: { children: ReactNode }) => {
  const { text } = useInternationalization()
  const projectState = useProjectState()

  const callbackRef = useRef()
  const saveProjectRef = useRef()

  const [saveProjectConfirmModal, setSaveProjectConfirmModal] = useState(false)

  const STRINGS = text.modals.saveProject

  const handleGoToDiffProject = async (cb: () => void, handleSaveProject: () => void) => {
    // @ts-ignore
    callbackRef.current = cb
    // @ts-ignore
    saveProjectRef.current = handleSaveProject
    if (projectState.editedProjectId || projectState.projectEmpty) {
      cb()
    } else {
      setSaveProjectConfirmModal(true)
    }
  }

  return (
    <GoToDiffProjectStateContext.Provider
      value={{
        handleGoToDiffProject,
        goToDiffProjectCallback: callbackRef.current,
      }}
    >
      <SaveProjectConfirmModal
        open={saveProjectConfirmModal}
        title={STRINGS.title}
        subtitle={STRINGS.subtitle}
        illustration={<SaveProjectIllustration />}
        onClose={() => {
          setSaveProjectConfirmModal(false)
          projectState.setOpenNewProject(false)
        }}
        onSkip={async () => {
          // @ts-ignore
          await callbackRef?.current()
        }}
        onSave={async () => {
          // @ts-ignore
          const res = await saveProjectRef?.current()
          if (res) {
            // @ts-ignore
            await callbackRef?.current()
          } else {
            setSaveProjectConfirmModal(false)
          }
        }}
      />
      {children}
    </GoToDiffProjectStateContext.Provider>
  )
}

export const useGoToDiffProjectState = () => React.useContext<FetchType>(GoToDiffProjectStateContext)
